const changepassword = (resolve) => {
  require.ensure(
    ["@/components/CMS/change-password/index.vue"],
    () => {
      resolve(require("@/components/CMS/change-password/index.vue"));
    },
    "changepassword"
  );
};
const userlogs = (resolve) => {
  require.ensure(
    ["@/components/CMS/user-logs/index.vue"],
    () => {
      resolve(require("@/components/CMS/user-logs/index.vue"));
    },
    "userlogs"
  );
};
const layout = (resolve) => {
  require.ensure(
    ["@/layouts/Layout2.vue"],
    () => {
      resolve(require("@/layouts/Layout2.vue"));
    },
    "layout"
  );
};

const contactusList = (resolve) => {
  require.ensure(
    ["@/components/CRM/contact-us/list/index.vue"],
    () => {
      resolve(require("@/components/CRM/contact-us/list/index.vue"));
    },
    "contactus"
  );
};
const leadList = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/list/active-leads/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/list/active-leads/index.vue"));
    },
    "leadList"
  );
};
const UnAssignLeadList = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/list/Unassign-leads/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/list/Unassign-leads/index.vue"));
    },
    "UnAssignLeadList"
  );
};
const DeletedLeadList = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/list/deleted-leads/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/list/deleted-leads/index.vue"));
    },
    "DeletedLeadList"
  );
};
const leadListindi = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/list/queue-leads/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/list/queue-leads/index.vue"));
    },
    "lead-list-individual"
  );
};
const agentlist = (resolve) => {
  require.ensure(
    ["@/components/CMS/agent-list/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/agent-list/list/index.vue"));
    },
    "agentlist"
  );
};
const statistics = (resolve) => {
  require.ensure(
    ["@/components/CMS/statistics/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/statistics/list/index.vue"));
    },
    "statistics"
  );
};
const systems = (resolve) => {
  require.ensure(
    ["@/components/CMS/systems/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/systems/list/index.vue"));
    },
    "systems"
  );
};
const leadManage = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/manage/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/manage/index.vue"));
    },
    "lead-manage"
  );
};

const addleadManage = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/manage/lead-add.vue"],
    () => {
      resolve(require("@/components/CRM/leads/manage/lead-add.vue"));
    },
    "add-lead-manage"
  );
};

const takeLead = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/manage/take-lead.vue"],
    () => {
      resolve(require("@/components/CRM/leads/manage/take-lead.vue"));
    },
    "take-lead"
  );
};
const salesList = (resolve) => {
  require.ensure(
    ["@/components/CRM/sales/list/index.vue"],
    () => {
      resolve(require("@/components/CRM/sales/list/index.vue"));
    },
    "salesList"
  );
};
const salesReferenceList = (resolve) => {
  require.ensure(
    ["@/components/CRM/sales/list/sale-refrence/index.vue"],
    () => {
      resolve(require("@/components/CRM/sales/list/sale-refrence/index.vue"));
    },
    "salesReferenceList"
  );
};

const deletedSalesReferenceList = (resolve) => {
  require.ensure(
    ["@/components/CRM/sales/list/deleted-sale-refrence/index.vue"],
    () => {
      resolve(
        require("@/components/CRM/sales/list/deleted-sale-refrence/index.vue")
      );
    },
    "deletedSalesReferenceList"
  );
};
const salesManage = (resolve) => {
  require.ensure(
    ["@/components/CRM/sales/manage/index.vue"],
    () => {
      resolve(require("@/components/CRM/sales/manage/index.vue"));
    },
    "sales-manage"
  );
};
const otherLeads = (resolve) => {
  require.ensure(
    ["@/components/CRM/leads/list/other-leads/index.vue"],
    () => {
      resolve(require("@/components/CRM/leads/list/other-leads/index.vue"));
    },
    "other-leads-list"
  );
};

const reportExport = (resolve) => {
  require.ensure(
    ["@/components/CRM/report-export/index.vue"],
    () => {
      resolve(require("@/components/CRM/report-export/index.vue"));
    },
    "reportExport"
  );
};
// '/other-leads'
export default [
  {
    path: "/systems",
    name: "systems",
    component: systems,
  },
  {
    path: "/contactus",
    name: "contactus",
    component: contactusList,
  },
  {
    path: "user-logs",
    name: "User Logs",
    component: userlogs,
  },
  {
    path: "statistics",
    name: "statistics",
    component: statistics,
  },
  {
    path: "change-password",
    name: "Change Password",
    component: changepassword,
  },
  {
    path: "agent-list",
    name: "Agent List",
    component: agentlist,
  },
  {
    path: "/lead",
    name: "leadList",
    component: leadList,
  },
  {
    path: "/unassigned-leads",
    name: "UnAssignLeadList",
    component: UnAssignLeadList,
  },
  {
    path: "/deleted-leads",
    name: "DeletedLeadList",
    component: DeletedLeadList,
  },
  {
    path: "/leads/:status",
    name: "lead list individual",
    component: leadListindi,
  },
  {
    path: "/lead/add-lead",
    name: "Add Lead Manage",
    component: addleadManage,
  },
  {
    path: "/take-lead/:dialer",
    name: "Take Lead",
    component: takeLead,
  },
  {
    path: "/lead/:id",
    name: "Lead Manage",
    component: leadManage,
  },
  {
    path: "/deleted-sales",
    name: "deletedSales",
    component: deletedSalesReferenceList,
  },

  {
    path: "/sales",
    name: "sales",
    component: salesReferenceList,
  },
  {
    path: "/sales/:status",
    name: "sales",
    component: salesList,
  },
  {
    path: "/sales_manage/:id",
    name: "sales Manage",
    component: salesManage,
  },
  {
    path: "/other-leads",
    name: "other leads",
    component: otherLeads,
  },
  {
    path: "/report-export",
    name: "report export",
    component: reportExport,
  },
];
