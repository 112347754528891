<template>
  <div id="TargetModal">
    <transition name="modal">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit()">
          <div class="modal-mask">
            <div class="modal modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content" style="background-color: white;">
                  <div class="modal-header p-0 pt-1">
                    <div class="d-flex col-md-11 p-0">
                      <h1 class="ml-3">Set Your Monthly GP Target</h1>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text" id="monthly-target" v-model="targetUpdated"
                          placeholder="Enter Monthly Target"
                          onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')">
                        <!-- <button id="set-target-btn" @click="openAgentDashBoard">Set Target</button> -->
                        <button v-if="!isTargetSet" id="set-target-btn" @click="openAgentDashBoard">
                          Set Target
                        </button>
                        <div v-else class="d-flex">
                          <button id="update-target-btn" @click="updateTarget" style="width: 50%">
                            Update Target
                          </button>
                          <button id="update-target-btn" @click="closeModal" class="ml-2" style="width: 50%">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
import store from "@/store/index.js";
export default {
  name: 'TargetModal',
  data: () => ({
    targetUpdated: "",
    isTargetSet: false,
    user: JSON.parse(atob(localStorage.getItem("user"))),
  }),
  props: {
    showTargetModal: {
      type: Boolean,
      required: true
    },
    selectedAgent: {
      type: Number,
      required: true
    },
    getAgentDashBoardData: {
      type: Function,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:showTargetModal', false);
    },
    openAgentDashBoard() {
      const _vm = this;
      store.state.isLoaderShow = true;
      _vm.axios
        .get("/monthly-target/" + _vm.user.id + "/" + _vm.targetUpdated)
        .then(function (response) {
          localStorage.setItem("targetUpdated", _vm.targetUpdated);
          _vm.isTargetSet = true;
          store.state.isLoaderShow = false;
          _vm.$emit('update:showTargetModal', false);
        })
        .catch(function () { });
    },
    updateTarget() {
      const _vm = this;
      store.state.isLoaderShow = true;
      _vm.axios
        .get("/update-monthly-target/" + _vm.selectedAgent + "/" + _vm.targetUpdated)
        .then(function (response) {
          localStorage.setItem("targetUpdated", _vm.targetUpdated);
          _vm.isTargetSet = true;
          store.state.isLoaderShow = false;
          _vm.$emit('update:showTargetModal', false);
          _vm.getAgentDashBoardData();
        })
        .catch(function () { });
    },

  },
  computed: {

  },
  mounted() {
    const savedTarget = localStorage.getItem("targetUpdated");
    this.isTargetSet = savedTarget && savedTarget !== "0.00";
    this.targetInput = savedTarget ? savedTarget : "0.00";
  }
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}

.inthei {
  height: 33px;
}

.bgcolor {
  background-color: #d3d3d366;
  padding-top: 0px;
  padding-bottom: 0px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}





* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}


body {
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  /* Padding for better spacing on mobile */
}

.dashboard {
  width: 100%;
  /* max-width: 1200px; */
  padding: 20px;
  /* background: white; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  overflow-x: hidden;
  height: 100vh;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  /* Adjusted for readability on small screens */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  /* Adjusted min-width for smaller devices */
  gap: 20px;
}

.card {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.metric {
  margin-bottom: 10px;
}

.metric p {
  font-size: 14px;
  color: #666;
}

.metric h3 {
  font-size: 20px;
  /* Adjusted font size for readability */
  color: #333;
  margin-top: 5px;
}

.month-selector {
  margin-bottom: 15px;
  width: fit-content;
}

select,
input[type="text"] {
  padding: 10px;
  /* Increased padding for touch interaction */
  font-size: 16px;
  /* Increased font size for readability */
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}

button {
  padding: 10px 16px;
  /* Increased padding for better touch interaction */
  font-size: 16px;
  /* Increased font size */
  border-radius: 4px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  width: 100%;
  /* Full width for buttons on small screens */
  margin-top: 10px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#target-section {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  /* Limit width for presentation on large screens */
}

@media (max-width: 768px) {
  body {
    padding: 10px;
    /* Reduce padding on smaller devices */
  }

  .card h2 {
    font-size: 16px;
    /* Smaller heading for better fit on small screens */
  }

  .metric h3 {
    font-size: 18px;
    /* Slightly smaller for small screens */
  }

  button {
    font-size: 14px;
    /* Smaller button text on small screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 20px;
    /* Smaller heading for small mobile screens */
  }

  .card {
    padding: 15px;
    /* Reduce padding on small devices */
  }

  select,
  input[type="text"] {
    font-size: 14px;
    /* Smaller font size for small screens */
    padding: 8px;
    /* Less padding for small screens */
  }

  button {
    padding: 8px 12px;
    /* Smaller padding for small screens */
    font-size: 14px;
  }
}
</style>
