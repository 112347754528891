const layout = resolve => {
	require.ensure(
		["@/layouts/Layout2.vue"],
		() => {
			resolve(require("@/layouts/Layout2.vue"));
		},
		"layout"
	);
};
const assetLibraryLiList = resolve => {
	require.ensure(
		["@/components/AssetLibrary/asset-library/list/index.vue"],
		() => {
			resolve(require("@/components/AssetLibrary/asset-library/list/index.vue"));
		},
		"asset-library"
	);
};




export default [
	{
		path: "/asset-library",
		name: "asset-library",
		component: assetLibraryLiList
	},


]
