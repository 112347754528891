const layout = (resolve) => {
  require.ensure(
    ["@/layouts/Layout2.vue"],
    () => {
      resolve(require("@/layouts/Layout2.vue"));
    },
    "layout"
  );
};
const emailMarketingList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/Campaign-template/list/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/Campaign-template/list/index.vue")
      );
    },
    "Campaign-template"
  );
};
const emailMarketingManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/Campaign-template/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/Campaign-template/manage/index.vue")
      );
    },
    "Campaign-template-manage"
  );
};

const emailfollowupTemplateList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-followup-templates/list/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/email-followup-templates/list/index.vue")
      );
    },
    "email-followup-template"
  );
};
const emailfollowupTemplateManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-followup-templates/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/email-followup-templates/manage/index.vue")
      );
    },
    "email-followup-template-manage"
  );
};
const emailStaticTemplateList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-static-templates/list/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/email-static-templates/list/index.vue")
      );
    },
    "email-static-template"
  );
};
const emailStaticTemplateManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-static-templates/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/email-static-templates/manage/index.vue")
      );
    },
    "email-static-template-manage"
  );
};
const emailCampaignList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-campaign/list/index.vue"],
    () => {
      resolve(require("@/components/Marketing/email-campaign/list/index.vue"));
    },
    "email-campaign"
  );
};
const emailCampaignManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-campaign/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/email-campaign/manage/index.vue")
      );
    },
    "email-campaign-manage"
  );
};
const emailSenderList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-sender/list/index.vue"],
    () => {
      resolve(require("@/components/Marketing/email-sender/list/index.vue"));
    },
    "email-sender"
  );
};
const emailSenderManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/email-sender/manage/index.vue"],
    () => {
      resolve(require("@/components/Marketing/email-sender/manage/index.vue"));
    },
    "email-sender-manage"
  );
};
const audienceList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/audience/list/index.vue"],
    () => {
      resolve(require("@/components/Marketing/audience/list/index.vue"));
    },
    "audience"
  );
};
const audienceManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/audience/manage/index.vue"],
    () => {
      resolve(require("@/components/Marketing/audience/manage/index.vue"));
    },
    "audience-manage"
  );
};

const smsfollowupMarketingList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/sms-followup-template/list/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/sms-followup-template/list/index.vue")
      );
    },
    "sms-followup-template"
  );
};
const smsfollowuptemplateManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/sms-followup-template/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/sms-followup-template/manage/index.vue")
      );
    },
    "sms-followup-template-manage"
  );
};
const smsstaticMarketingList = (resolve) => {
  require.ensure(
    ["@/components/Marketing/sms-static-template/list/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/sms-static-template/list/index.vue")
      );
    },
    "sms-static-template"
  );
};
const smsstatictemplateManage = (resolve) => {
  require.ensure(
    ["@/components/Marketing/sms-static-template/manage/index.vue"],
    () => {
      resolve(
        require("@/components/Marketing/sms-static-template/manage/index.vue")
      );
    },
    "sms-static-template-manage"
  );
};
export default [
  {
    path: "/campaign-template",
    name: "Campaign-template",
    component: emailMarketingList,
  },
  {
    path: "/Campaign-template/add-Campaign-Template",
    name: "add-Campaign-Template",
    component: emailMarketingManage,
  },
  {
    path: "/Campaign-template/update-Campaign-Template/:id",
    name: "update-Campaign-Template",
    component: emailMarketingManage,
  },
  {
    path: "/email-followup-template",
    name: "followup-template",
    component: emailfollowupTemplateList,
  },
  {
    path: "/email-followup-template/add-email-followup-Template",
    name: "add-email-followup-Template",
    component: emailfollowupTemplateManage,
  },
  {
    path: "/email-followup-template/update-email-followup-Template/:id",
    name: "update-email-followup-Template",
    component: emailfollowupTemplateManage,
  },
  {
    path: "/email-static-template",
    name: "static-template",
    component: emailStaticTemplateList,
  },
  {
    path: "/email-static-template/add-email-static-Template",
    name: "add-email-static-Template",
    component: emailStaticTemplateManage,
  },
  {
    path: "/email-static-template/update-email-static-Template/:id",
    name: "update-email-static-Template",
    component: emailStaticTemplateManage,
  },
  {
    path: "/email-campaign",
    name: "email-campaign",
    component: emailCampaignList,
  },
  {
    path: "/email-campaign/add-Email-campaign",
    name: "add-Email-campaign",
    component: emailCampaignManage,
  },
  {
    path: "/email-campaign/update-Email-campaign/:id",
    name: "update-Email-campaign",
    component: emailCampaignManage,
  },
  {
    path: "/email-sender",
    name: "email-sender",
    component: emailSenderList,
  },
  {
    path: "/email-sender/add-email-sender",
    name: "add-email-sender",
    component: emailSenderManage,
  },
  {
    path: "/email-sender/update-email-sender/:id",
    name: "email-sender-manage",
    component: emailSenderManage,
  },
  {
    path: "/audience",
    name: "audience",
    component: audienceList,
  },
  {
    path: "/audience/add-audience",
    name: "add-audience",
    component: audienceManage,
  },
  {
    path: "/audience/update-audience/:id",
    name: "update-audience",
    component: audienceManage,
  },
  {
    path: "/sms-followup-template",
    name: "sms-followup-template",
    component: smsfollowupMarketingList,
  },
  {
    path: "/sms-followup-template/add-sms-followup-template",
    name: "add-sms-followup-template",
    component: smsfollowuptemplateManage,
  },
  {
    path: "/sms-followup-template/update-sms-followup-template/:id",
    name: "update-sms-followup-template",
    component: smsfollowuptemplateManage,
  },
  {
    path: "/sms-static-template",
    name: "sms-static-template",
    component: smsstaticMarketingList,
  },
  {
    path: "/sms-static-template/add-sms-static-template",
    name: "add-sms-static-template",
    component: smsstatictemplateManage,
  },
  {
    path: "/sms-static-template/update-sms-static-template/:id",
    name: "update-sms-static-template",
    component: smsstatictemplateManage,
  },
];
