const layout = resolve => {
	require.ensure(
		["@/layouts/Layout2.vue"],
		() => {
			resolve(require("@/layouts/Layout2.vue"));
		},
		"layout"
	);
};
const tagList = resolve => {
	require.ensure(
		["@/components/Blog/tag/list/index.vue"],
		() => {
			resolve(require("@/components/Blog/tag/list/index.vue"));
		},
		"tag"
	);
};
const tagManage = resolve => {
	require.ensure(
		["@/components/Blog/tag/manage/index.vue"],
		() => {
			resolve(require("@/components/Blog/tag/manage/index.vue"));
		},
		"tag-manage"
	);
};

const categoryList = resolve => {
	require.ensure(
		["@/components/Blog/category/list/index.vue"],
		() => {
			resolve(require("@/components/Blog/category/list/index.vue"));
		},
		"category"
	);
};
const categoryManage = resolve => {
	require.ensure(
		["@/components/Blog/category/manage/index.vue"],
		() => {
			resolve(require("@/components/Blog/category/manage/index.vue"));
		},
		"category-manage"
	);
};
const blogPostList = resolve => {
	require.ensure(
		["@/components/Blog/blog-post/list/index.vue"],
		() => {
			resolve(require("@/components/Blog/blog-post/list/index.vue"));
		},
		"blog-post"
	);
};
const blogPostManage = resolve => {
	require.ensure(
		["@/components/Blog/blog-post/manage/index.vue"],
		() => {
			resolve(require("@/components/Blog/blog-post/manage/index.vue"));
		},
		"blog-post-manage"
	);
};


export default [
	{
		path: "/tag",
		name: "tag",
		component: tagList
	},
	{
		path: "/tag/add-tag",
		name: "tag-manage add",
		component: tagManage
	},
	{
		path: "/tag/update-tag/:id",
		name: "tag-manage update",
		component: tagManage
	},
	{
		path: "/category",
		name: "category",
		component: categoryList
	},
	{
		path: "/category/add-category",
		name: "category-manage add",
		component: categoryManage
	},

	{
		path: "/category/update-category/:id",
		name: "category-manage update",
		component: categoryManage
	},
	{
		path: "/blog-post",
		name: "blog-post",
		component: blogPostList
	},
	{
		path: "/blog-post/add-blog-post",
		name: "blog-post-manage add",
		component: blogPostManage
	},

	{
		path: "/blog-post/update-blog-post/:id",
		name: "blog-post-manage update",
		component: blogPostManage
	},
]
