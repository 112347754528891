<template>
  <div :class="{ 'opacity-6': $store.state.isLoaderShow }">
    <router-view />
    <div class="loader" v-if="$store.state.isLoaderShow"></div>
  </div>

</template>
<script>
import { core } from './config/pluginInit'

export default {
  name: 'App',
  components: {
  },
  mounted() {
    core.mainIndex()
  }
}
</script>
<style lang="scss">
@import "assets/scss/style.scss";
</style>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 0;
  right: 0;
  /* width: 300px; */
  /* height: 400px; */
  /* border: 1px solid #ccc; */
  /* background-color: white; */
  z-index: 1000;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}
</style>
