import axios from 'axios'
import router from '@/router/index'
import store from "./store";
import Vue from 'vue';
import { ALERT_CLASS, FADE_IN, DELAY, FADE_OUT } from "@/constants";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

HTTP.interceptors.request.use(
  function (config) {
    config.headers.authorization =
      'Bearer ' + localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)
    if (config.method != "get" && !store.state.isLoaderShow) {
      store.state.isLoaderShow = true;
    }
    return config

  },
  function (error) {
    Promise.reject(error);
  }
)
HTTP.interceptors.response.use(
  function (response) {
    store.state.isLoaderShow = false;
    if (response.config.method != "get") {
      Vue.$toast.success(response.data.msg);
    }
    return response
  },
  function (error) {
    store.state.isLoaderShow = false;
    $(".alert").removeClass(ALERT_CLASS.success);
    switch (error.response.status) {
      case 401: {
        $(".alert")
          .html(
            error.response.data.error +
            ": " +
            error.response.data.message
          )
          .addClass(ALERT_CLASS.danger)
          .fadeIn(FADE_IN)
          .delay(DELAY)
          .fadeOut(FADE_OUT);
        localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
        const rdr = encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)
        window.location.href = "/auth/login?rdr=" + rdr;
        break;
      }
      case 422: {
        let data = error.response.data;
        let alert_message = "<ul>";
        for (var k in data) {
          alert_message += "<li>" + data[k][0] + "</li>";
          Vue.$toast.error(data[k][0]);
        }
        alert_message += "</ul>";
        $(".alert")
          .html(alert_message)
          .addClass(ALERT_CLASS.danger)
          .fadeIn(FADE_IN)
          .delay(DELAY)
          .fadeOut(FADE_OUT);
        break;
      }
      case 403: {
        window.location.href = "/error/404";
        break;
      }
      case 308: {
        router.push('/auth/change-password/' + error.response.data.data);
        break;
      }
    }
    return Promise.reject(error);
  })
export default HTTP
