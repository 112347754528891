const airport = (resolve) => {
  require.ensure(
    ["@/components/CMS/airport/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/airport/list/index.vue"));
    },
    "airport"
  );
};
const airportManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/airport/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/airport/manage/index.vue"));
    },
    "airport-manage"
  );
};
const airlinesList = (resolve) => {
  require.ensure(
    ["@/components/CMS/airlines/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/airlines/list/index.vue"));
    },
    "airlines-list"
  );
};
const airlineManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/airlines/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/airlines/manage/index.vue"));
    },
    "airlines manage"
  );
};
const layout = (resolve) => {
  require.ensure(
    ["@/layouts/Layout2.vue"],
    () => {
      resolve(require("@/layouts/Layout2.vue"));
    },
    "layout"
  );
};

const pccsList = (resolve) => {
  require.ensure(
    ["@/components/CMS/pccs/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/pccs/list/index.vue"));
    },
    "pccs -list"
  );
};
const pccsManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/pccs/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/pccs/manage/index.vue"));
    },
    "pccs manage"
  );
};

const milageProgramsList = (resolve) => {
  require.ensure(
    ["@/components/CMS/milage-programs/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/milage-programs/list/index.vue"));
    },
    "milage-programs -list"
  );
};
const milageProgramsManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/milage-programs/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/milage-programs/manage/index.vue"));
    },
    "milage-programs manage"
  );
};
const departmentList = (resolve) => {
  require.ensure(
    ["@/components/CMS/department/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/department/list/index.vue"));
    },
    "department -list"
  );
};
const departmentManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/department/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/department/manage/index.vue"));
    },
    "department manage"
  );
};
const companyList = (resolve) => {
  require.ensure(
    ["@/components/CMS/company/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/company/list/index.vue"));
    },
    "company -list"
  );
};
const companyManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/company/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/company/manage/index.vue"));
    },
    "company manage"
  );
};
const topDealList = (resolve) => {
  require.ensure(
    ["@/components/CMS/topdeal/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/topdeal/list/index.vue"));
    },
    "topdeal -list"
  );
};
const topDealManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/topdeal/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/topdeal/manage/index.vue"));
    },
    "topdeal manage"
  );
};
const faqCategories = (resolve) => {
  require.ensure(
    ["@/components/CMS/faq-categories/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/faq-categories/list/index.vue"));
    },
    "faq-categories"
  );
};
const faqCategoriesManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/faq-categories/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/faq-categories/manage/index.vue"));
    },
    "faq-categories-manage"
  );
};
const faq = (resolve) => {
  require.ensure(
    ["@/components/CMS/faq/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/faq/list/index.vue"));
    },
    "faq"
  );
};
const faqManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/faq/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/faq/manage/index.vue"));
    },
    "faq-manage"
  );
};
const teams = (resolve) => {
  require.ensure(
    ["@/components/CMS/teams/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/teams/list/index.vue"));
    },
    "teams"
  );
};
const teamsManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/teams/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/teams/manage/index.vue"));
    },
    "teams-manage"
  );
};
const roles = (resolve) => {
  require.ensure(
    ["@/components/CMS/roles/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/roles/list/index.vue"));
    },
    "roles"
  );
};
const rolesManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/roles/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/roles/manage/index.vue"));
    },
    "roles-manage"
  );
};
const subscriber = (resolve) => {
  require.ensure(
    ["@/components/CMS/subscriber/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/subscriber/list/index.vue"));
    },
    "subscriber"
  );
};
const subscriberManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/subscriber/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/subscriber/manage/index.vue"));
    },
    "subscriber-manage"
  );
};
const userList = (resolve) => {
  require.ensure(
    ["@/components/CMS/users/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/users/list/index.vue"));
    },
    "user"
  );
};
const userManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/users/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/users/manage/index.vue"));
    },
    "user-manage"
  );
};
const cityGroupList = (resolve) => {
  require.ensure(
    ["@/components/CMS/city-group/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/city-group/list/index.vue"));
    },
    "city-group"
  );
};
const cityGroupManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/city-group/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/city-group/manage/index.vue"));
    },
    "city-group-manage"
  );
};
const airportPricesList = (resolve) => {
  require.ensure(
    ["@/components/CMS/airport-prices/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/airport-prices/list/index.vue"));
    },
    "airport-prices"
  );
};
const airportPricesManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/airport-prices/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/airport-prices/manage/index.vue"));
    },
    "airport-prices-manage"
  );
};
const reviewList = (resolve) => {
  require.ensure(
    ["@/components/CMS/review/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/review/list/index.vue"));
    },
    "review"
  );
};
const reviewManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/review/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/review/manage/index.vue"));
    },
    "review-manage"
  );
};
const clientList = (resolve) => {
  require.ensure(
    ["@/components/CMS/client/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/client/list/index.vue"));
    },
    "client"
  );
};
const otherClientList = (resolve) => {
  require.ensure(
    ["@/components/CRM/other-client/list/index.vue"],
    () => {
      resolve(require("@/components/CRM/other-client/list/index.vue"));
    },
    "otherClientList"
  );
};
const OldClientList = (resolve) => {
  require.ensure(
    ["@/components/CRM/old-client/index.vue"],
    () => {
      resolve(require("@/components/CRM/old-client/index.vue"));
    },
    "OldClientList"
  );
};
const clientManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/client/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/client/manage/index.vue"));
    },
    "client-manage"
  );
};
const landingCountryList = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-country/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-country/list/index.vue"));
    },
    "landing-country"
  );
};
const landingCountryManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-country/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-country/manage/index.vue"));
    },
    "landing-country-manage"
  );
};
const seoCountryList = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-country/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-country/list/index.vue"));
    },
    "seoCountry"
  );
};
const seoCountryManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-country/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-country/manage/index.vue"));
    },
    "seoCountry-manage"
  );
};
const seoArilinesList = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-airlines/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-airlines/list/index.vue"));
    },
    "seoairlines"
  );
};
const seoArilinesManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-airlines/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-airlines/manage/index.vue"));
    },
    "seoarilines-manage"
  );
};
const seoRegionList = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-region/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-region/list/index.vue"));
    },
    "seoRegion"
  );
};
const seoRegionManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-region/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-region/manage/index.vue"));
    },
    "seoRegion-manage"
  );
};
const seoCityList = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-city/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-city/list/index.vue"));
    },
    "city"
  );
};
const seoCityManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/seo-city/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/seo-city/manage/index.vue"));
    },
    "city-manage"
  );
};
const landingAirlineList = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-airlines/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-airlines/list/index.vue"));
    },
    "landing-airlines"
  );
};
const landingAirlineManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-airlines/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-airlines/manage/index.vue"));
    },
    "landing-airlines-manage"
  );
};
const landingCityList = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-city/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-city/list/index.vue"));
    },
    "landing-city"
  );
};
const landingCityManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/landing-city/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/landing-city/manage/index.vue"));
    },
    "landing-city-manage"
  );
};
const ccFeeList = (resolve) => {
  require.ensure(
    ["@/components/CMS/cc-fee/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/cc-fee/list/index.vue"));
    },
    "cc-fee"
  );
};
const ccFeeManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/cc-fee/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/cc-fee/manage/index.vue"));
    },
    "cc-fee-manage"
  );
};
const bankDetailList = (resolve) => {
  require.ensure(
    ["@/components/CMS/bank-details/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/bank-details/list/index.vue"));
    },
    "bank-detail"
  );
};
const bankDetailManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/bank-details/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/bank-details/manage/index.vue"));
    },
    "bank-detail-manage"
  );
};
const billingCountryList = (resolve) => {
  require.ensure(
    ["@/components/CMS/billing-country/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/billing-country/list/index.vue"));
    },
    "billing-country"
  );
};
const billingCountryManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/billing-country/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/billing-country/manage/index.vue"));
    },
    "billing-country-manage"
  );
};
const billingStateList = (resolve) => {
  require.ensure(
    ["@/components/CMS/billing-state/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/billing-state/list/index.vue"));
    },
    "billing-state"
  );
};
const billingStateManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/billing-state/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/billing-state/manage/index.vue"));
    },
    "billing-state-manage"
  );
};
const bankAccountList = (resolve) => {
  require.ensure(
    ["@/components/CMS/bank-accounts/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/bank-accounts/list/index.vue"));
    },
    "bank-account"
  );
};
const bankAccountManage = (resolve) => {
  require.ensure(
    ["@/components/CMS/bank-accounts/manage/index.vue"],
    () => {
      resolve(require("@/components/CMS/bank-accounts/manage/index.vue"));
    },
    "bank-account-manage"
  );
};
const userDeleteList = (resolve) => {
  require.ensure(
    ["@/components/CMS/users/list/deleted-user.vue"],
    () => {
      resolve(require("@/components/CMS/users/list/deleted-user.vue"));
    },
    "delete-user"
  );
};
const bladeVariables = (resolve) => {
  require.ensure(
    ["@/components/CMS/blade-variables/list/index.vue"],
    () => {
      resolve(require("@/components/CMS/blade-variables/list/index.vue"));
    },
    "blade-variables"
  );
};
const ErrorPage = () => import("@/views/ErrorPages/ErrorPage.vue");

export default [
  {
    path: "/company",
    name: "company",
    component: companyList,
    meta: { auth: true },
  },
  {
    path: "/company/add-company",
    name: "company Manage Add",
    component: companyManage,
    meta: { auth: true },
  },
  {
    path: "/company/update-company/:id",
    name: "company Manage Update",
    component: companyManage,
    meta: { auth: true },
  },
  {
    path: "/airports",
    name: "airports",
    component: airport,
    meta: { auth: true },
  },
  {
    path: "/airport/add-airport",
    name: "Airport Add",
    component: airportManage,
    meta: { auth: true },
  },
  {
    path: "/airport/update-airport/:id",
    name: "Airport Update",
    component: airportManage,
    meta: { auth: true },
  },

  {
    path: "/airlines",
    name: "airlines",
    component: airlinesList,
    meta: { auth: true },
  },
  {
    path: "/airlines/add-airline",
    name: "Airlines Add",
    component: airlineManage,
    meta: { auth: true },
  },
  {
    path: "/airlines/update-airline/:id",
    name: "Airlines Update",
    component: airlineManage,
    meta: { auth: true },
  },
  {
    path: "/pccs",
    name: "pccs",
    component: pccsList,
    meta: { auth: true },
  },
  {
    path: "/pccs/add-pcc",
    name: "Pccs Add",
    component: pccsManage,
    meta: { auth: true },
  },
  {
    path: "/pccs/update-pcc/:id",
    name: "Pccs Update",
    component: pccsManage,
    meta: { auth: true },
  },
  {
    path: "/milage-programs",
    name: "milageprograms",
    component: milageProgramsList,
    meta: { auth: true },
  },
  {
    path: "/milage-programs/add-milage-program",
    name: "Milage programs Manage Add",
    component: milageProgramsManage,
    meta: { auth: true },
  },
  {
    path: "/milage-programs/update-milage-program/:id",
    name: "Milage programs Manage Update",
    component: milageProgramsManage,
    meta: { auth: true },
  },
  {
    path: "/department",
    name: "department",
    component: departmentList,
    meta: { auth: true },
  },
  {
    path: "/department/add-department",
    name: "department Manage Add",
    component: departmentManage,
    meta: { auth: true },
  },
  {
    path: "/department/update-department/:id",
    name: "department Manage Update",
    component: departmentManage,
    meta: { auth: true },
  },

  {
    path: "/topdeal",
    name: "topdeal",
    component: topDealList,
    meta: { auth: true },
  },
  {
    path: "/topdeal/add-topdeal",
    name: "topdeal Manage Add",
    component: topDealManage,
    meta: { auth: true },
  },
  {
    path: "/topdeal/update-topdeal/:id",
    name: "topdeal Manage Update",
    component: topDealManage,
    meta: { auth: true },
  },
  {
    path: "/faq-categories",
    name: "faq-categories",
    component: faqCategories,
    meta: { auth: true },
  },
  {
    path: "/faq-categorie/add-faq-categorie",
    name: "faq-categories Manage Add",
    component: faqCategoriesManage,
    meta: { auth: true },
  },
  {
    path: "/faq-categorie/update-faq-categorie/:id",
    name: "faq-categories Manage Update",
    component: faqCategoriesManage,
    meta: { auth: true },
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
    meta: { auth: true },
  },
  {
    path: "/faq/update-faq/:id",
    name: "faq Manage Update",
    component: faqManage,
    meta: { auth: true },
  },
  {
    path: "/faq/add-faq",
    name: "faq Manage Add",
    component: faqManage,
    meta: { auth: true },
  },
  {
    path: "/teams",
    name: "teams",
    component: teams,
    meta: { auth: true },
  },
  {
    path: "/teams/add-team",
    name: "teams Manage Add",
    component: teamsManage,
    meta: { auth: true },
  },
  {
    path: "/teams/update-team/:id",
    name: "teams Manage Update",
    component: teamsManage,
    meta: { auth: true },
  },
  {
    path: "/roles",
    name: "roles",
    component: roles,
    meta: { auth: true },
  },
  {
    path: "/role/add-role",
    name: "roles Manage Add",
    component: rolesManage,
    meta: { auth: true },
  },
  {
    path: "/role/update-role/:id",
    name: "roles Manage Update",
    component: rolesManage,
    meta: { auth: true },
  },
  {
    path: "/subscribers",
    name: "subscriber",
    component: subscriber,
    meta: { auth: true },
  },
  {
    path: "/subscribers/:id",
    name: "subscriber Manage Update",
    component: subscriberManage,
    meta: { auth: true },
  },
  {
    path: "/citygroups",
    name: "citygroup",
    component: cityGroupList,
    meta: { auth: true },
  },
  {
    path: "/citygroup/add-citygroup",
    name: "citygroup Manage Add",
    component: cityGroupManage,
    meta: { auth: true },
  },
  {
    path: "/citygroup/update-citygroup/:id",
    name: "citygroup Manage Update",
    component: cityGroupManage,
    meta: { auth: true },
  },
  {
    path: "/users",
    name: "users",
    component: userList,
  },
  {
    path: "/user/deleted-users",
    name: "delete-user",
    component: userDeleteList,
  },
  {
    path: "/user/update-user/:id",
    name: "User manage Update",
    component: userManage,
  },
  {
    path: "/user/add-user",
    name: "User manage Add",
    component: userManage,
  },
  {
    path: "/destinationprices",
    name: "airportprices",
    component: airportPricesList,
  },
  {
    path: "/destinationprices/add-airport-price",
    name: "airportprices manage add",
    component: airportPricesManage,
  },
  {
    path: "/destinationprices/update-airport-price/:id",
    name: "airportprices manage update",
    component: airportPricesManage,
  },
  {
    path: "/user-review",
    name: "review",
    component: reviewList,
  },
  {
    path: "/user-review/add-user-review",
    name: "review manage add",
    component: reviewManage,
  },
  {
    path: "/user-review/update-user-review/:id",
    name: "review manage update",
    component: reviewManage,
  },
  {
    path: "/clients",
    name: "client",
    component: clientList,
  },
  {
    path: "/other-clients",
    name: "otherClientList",
    component: otherClientList,
  },
  {
    path: "/old-clients",
    name: "OldClientList",
    component: OldClientList,
  },
  {
    path: "/clients/add-client",
    name: "client manage add",
    component: clientManage,
  },
  {
    path: "/clients/update-client/:id",
    name: "client manage update",
    component: clientManage,
  },
  {
    path: "/landing-countries",
    name: "landing-country",
    component: landingCountryList,
  },
  {
    path: "/landing-countries/add-landing-country",
    name: "landing-country manage add",
    component: landingCountryManage,
  },
  {
    path: "/landing-countries/update-landing-country/:id",
    name: "landing-country manage update",
    component: landingCountryManage,
  },

  {
    path: "/seo-region",
    name: "seoRegion",
    component: seoRegionList,
  },
  {
    path: "/seo-region/add-seo-region",
    name: "seoRegion manage add",
    component: seoRegionManage,
  },
  {
    path: "/seo-region/update-seo-region/:company_id/:id",
    name: "seoRegion manage update",
    component: seoRegionManage,
  },
  {
    path: "/seo-cities",
    name: "seoCity",
    component: seoCityList,
  },
  {
    path: "/seo-cities/add-seo-city",
    name: "seoCity manage add",
    component: seoCityManage,
  },
  {
    path: "/seo-cities/update-seo-city/:company_id/:id",
    name: "seoCity manage update",
    component: seoCityManage,
  },
  {
    path: "/landing-airlines",
    name: "landing-airline",
    component: landingAirlineList,
  },
  {
    path: "/landing-airlines/add-landing-airline",
    name: "landing-airline manage add",
    component: landingAirlineManage,
  },
  {
    path: "/landing-airlines/update-landing-airline/:id",
    name: "landing-airline manage update",
    component: landingAirlineManage,
  },
  {
    path: "error/:code",
    name: "error",
    meta: { auth: true },
    component: ErrorPage,
  },
  {
    path: "/seo-countries",
    name: "seoCountry",
    component: seoCountryList,
  },
  {
    path: "/seo-countries/add-seo-country",
    name: "seoCountry manage add",
    component: seoCountryManage,
  },
  {
    path: "/seo-countries/update-seo-country/:company_id/:id",
    name: "seoCountry manage update",
    component: seoCountryManage,
  },
  {
    path: "/seo-airlines",
    name: "seoarilines",
    component: seoArilinesList,
  },
  {
    path: "/seo-airlines/add-seo-airline",
    name: "seoarilines manage add",
    component: seoArilinesManage,
  },
  {
    path: "/seo-airlines/update-seo-airline/:company_id/:id",
    name: "seoarilines manage update",
    component: seoArilinesManage,
  },
  {
    path: "/landing-city",
    name: "landing-city",
    component: landingCityList,
  },
  {
    path: "/landing-city/add-landing-city",
    name: "landing-city manage add",
    component: landingCityManage,
  },
  {
    path: "/landing-city/update-landing-city/:id",
    name: "landing-city manage update",
    component: landingCityManage,
  },
  {
    path: "/cc-fee",
    name: "cc-fee",
    component: ccFeeList,
  },
  {
    path: "/cc-fee/add-cc-fee",
    name: "add-cc-fee",
    component: ccFeeManage,
  },
  {
    path: "/cc-fee/update-cc-fee/:id",
    name: "update-cc-fee",
    component: ccFeeManage,
  },

  {
    path: "/bank-detail",
    name: "bank-detail",
    component: bankDetailList,
  },
  {
    path: "/bank-detail/add-bank-detail",
    name: "add-bank-detail",
    component: bankDetailManage,
  },
  {
    path: "/bank-detail/update-bank-detail/:id",
    name: "update-bank-detail",
    component: bankDetailManage,
  },
  {
    path: "/bank-account",
    name: "bank-account",
    component: bankAccountList,
  },
  {
    path: "/bank-account/add-bank-account",
    name: "add-bank-account",
    component: bankAccountManage,
  },
  {
    path: "/bank-account/update-bank-account/:id",
    name: "update-bank-account",
    component: bankAccountManage,
  },
  {
    path: "/billing-countries",
    name: "billing-country",
    component: billingCountryList,
  },
  {
    path: "/billing-countries/add-billing-countries",
    name: "add-billing-country",
    component: billingCountryManage,
  },
  {
    path: "/billing-countries/update-billing-countries/:id",
    name: "update-billing-country",
    component: billingCountryManage,
  },
  {
    path: "/billing-states",
    name: "billing-states",
    component: billingStateList,
  },
  {
    path: "/billing-states/add-billing-states",
    name: "add-billing-states",
    component: billingStateManage,
  },
  {
    path: "/billing-states/update-billing-states/:id",
    name: "update-billing-states",
    component: billingStateManage,
  },
  {
    path: "/blade-variables",
    name: "blade Variables",
    component: bladeVariables,
    meta: { auth: true },
  },
];
