<template>
  <div style="border-radius: 8px;" :class="'iq-card ' + className">
    <slot name="cardImage" />
    <div style="border-radius: 8px 8px 0 0;" v-if="hasHeaderTitleSlot || hasHeaderActionSlot"
      :class="'iq-card-header d-flex pt-0' + headerClass">
      <div class="iq-header-title w-100">
        <slot name="headerTitle" />
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <slot name="headerAction" />
      </div>
    </div>
    <div :class="'iq-card-body pt-0 pb-0' + bodyClass" v-if="hasBodySlot">
      <slot name="body" />
    </div>

    <slot />
    <div v-if="hasFooterSlot" :class="'card-footer' + footerClass">
      <slot name="footer" />
    </div>

    <TargetModal v-if="showTargetModal" :showTargetModal.sync="showTargetModal"></TargetModal>
  </div>
</template>

<script>
import TargetModal from "../../Global-Component/agent_target/index.vue";

export default {
  name: 'iq-card',
  data() {
    return {
      // rc_widget: localStorage.getItem("rc_widget") === "true" || false,
      targetUpdated: localStorage.getItem("targetUpdated"),
      showTargetModal: false,
      slugs: JSON.parse(atob(localStorage.getItem("slugs"))),
    }
  },
  components: {
    TargetModal: TargetModal
  },
  props: {
    className: {
      type: String,
      default: ''
    },
    bodyClass: {
      type: String,
      default: ''
    },
    headerClass: {
      type: String,
      default: ''
    },
    footerClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasHeaderTitleSlot() {
      return !!this.$slots.headerTitle
    },
    hasHeaderActionSlot() {
      return !!this.$slots.headerAction
    },
    hasBodySlot() {
      return !!this.$slots.body
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    // hasDashboardAgentDataPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "dashboard-agent-data";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },
  },
  mounted() {
    let that = this;
    that.checkTarget();
    // that.handleWidgetChange();
    // that.$watch(() => localStorage.getItem("rc_widget"), (newValue, oldValue) => {
    //   if (newValue !== oldValue) {
    //     that.rc_widget = newValue === "true";
    //     that.handleWidgetChange();
    //   }
    // });

    that.pollingInterval = setInterval(that.checkLocalStorage, 1000);
  },
  methods: {

    checkTarget() {
      if (this.slugs.includes('dashboard-agent-data')) {
        if (this.targetUpdated == 0.00) {
          this.showTargetModal = true;
        } else {
          this.showTargetModal = false;
        }
      }
    },
    // handleWidgetChange() {
    //   if (this.rc_widget) {
    //     this.loadScript();
    //   } else {
    //     this.removeWidget();
    //   }
    // },
    // loadScript() {
    //   let existingScript = document.getElementById("rc-widget-script");
    //   if (!existingScript) {
    //     let rcs = document.createElement("script");
    //     rcs.src = "https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js";
    //     rcs.id = document.getElementsByTagName("script")[0]; // Assign an ID to the script for easy removal
    //     let rcs0 = document.getElementsByTagName("script")[0];
    //     rcs0.parentNode.insertBefore(rcs, rcs0);

    //     // Once the script is loaded, initialize the widget
    //     rcs.onload = () => {
    //       window.RingCentralEmbeddable = window.RingCentralEmbeddable || {};
    //       window.RingCentralEmbeddable.createWidget({
    //         // Widget configuration goes here
    //       });
    //     };
    //   }
    // },
    // removeWidget() {
    //   // Remove the widget's DOM elements if the widget is loaded
    //   let widgetElement = document.querySelector('.rc-widget'); // Adjust the selector as necessary
    //   if (widgetElement) {
    //     widgetElement.parentNode.removeChild(widgetElement);
    //   }

    //   // Cleanup the script from the DOM
    //   let script = document.getElementById("rc-widget-script");
    //   if (script) {
    //     script.parentNode.removeChild(script);
    //   }

    //   // Check if the widget API has a cleanup method to fully deactivate it
    //   if (window.RingCentralEmbeddable && window.RingCentralEmbeddable.destroy) {
    //     window.RingCentralEmbeddable.destroy();
    //   }
    // },
    // checkLocalStorage() {
    //   const currentWidgetValue = localStorage.getItem("rc_widget");
    //   if (currentWidgetValue !== this.rc_widget.toString()) {
    //     this.rc_widget = currentWidgetValue === "true";
    //     this.handleWidgetChange();
    //   }
    // }
  }
}

</script>
