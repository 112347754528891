import Vue from "vue";
import {
	ValidationObserver,
	ValidationProvider,
	extend,
	localize
} from "vee-validate/dist/vee-validate.full.esm";

import en from "vee-validate/dist/locale/en.json";
//import { required, alpha } from "vee-validate/dist/rules";
import * as rules from "vee-validate/dist/rules";
// install rules and localization
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule]);
});
extend("decimal", {
	validate: (value, { decimals = '*', separator = '.' } = {}) => {
	  if (value === null || value === undefined || value === '') {
		return {
		  valid: false
		};
	  }
	  if (Number(decimals) === 0) {
		return {
		  valid: /^-?\d*$/.test(value),
		};
	  }
	  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
	  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
  
	  return {
		valid: regex.test(value),
	  };
	},
	message: 'The {_field_} field must contain only decimal values'
  })
localize("en", en);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
