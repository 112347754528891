import Vue from "vue";
import VueRouter from "vue-router";
// import authRoutes from "./cms/auth.js";
import cmsRoutes from "./cms/index.js";
import crmRoutes from "./crm/index.js";
import marketingRoutes from "./Marketing/index.js";
import blogRoutes from "./blog/index.js";
import aseetRoutes from "./asset/index.js";
/* Layouts */
const Layout2 = () => import("../layouts/Layout2.vue");
const AuthLayout = () => import("../layouts/AuthLayouts/AuthLayout");
/* Dashboards View */
const Dashboard1 = () => import("../views/Dashboards/Dashboard1.vue");
const CompanyDashboards = () =>
  import("../views/Company-Dashboards/company-dashboard.vue");
const SignIn1 = () => import("../views/AuthPages/Default/login");
const SignUp1 = () => import("../views/AuthPages/Default/SignUp1");
const RecoverPassword1 = () =>
  import("../views/AuthPages/Default/auth/RecoverPassword1.vue");
const resetpassword = () =>
  import("../views/AuthPages/Default/auth/reset-password.vue");
const emailsent = () =>
  import("../views/AuthPages/Default/auth/email-sent.vue");
const ErrorPage = () => import("../views/ErrorPages/ErrorPage.vue");
const changepassword = () =>
  import("../views/AuthPages/Default/auth/change-password/index.vue");
/* Extra Pages */

Vue.use(VueRouter);

const authChildRoutes = (prop) => [
  {
    path: "login",
    name: prop + ".login",
    meta: { auth: true },
    component: SignIn1,
  },
  {
    path: "sign-up1",
    name: prop + ".sign-up1",
    meta: { auth: true },
    component: SignUp1,
  },
  {
    path: "/auth/forgot-password",
    name: "RecoverPassword1",
    // meta: { auth: true },
    component: RecoverPassword1,
  },
  {
    path: "/auth/reset-password/:token",
    name: "reset-password",
    // meta: { auth: true },
    component: resetpassword,
  },
  {
    path: "/auth/change-password/:data",
    name: "change-password",
    // meta: { auth: true },
    component: changepassword,
  },
  {
    path: "/auth/email-sent",
    name: "email-sent",
    // meta: { auth: true },
    component: emailsent,
  },
];

const routes = [
  {
    path: "/error/:code",
    name: "error_auth",
    meta: { auth: true },
    component: ErrorPage,
  },
  {
    path: "/",
    component: Layout2,
    meta: { auth: true },
    children: [
      {
        path: "/",
        name: "dashboard.home-1",
        meta: { auth: true, name: "Home 1" },
        component: Dashboard1,
      },
      {
        path: "/company-dashboard",
        name: "company-Dashboards.home-1",
        meta: { auth: true, name: "Home 1" },
        component: CompanyDashboards,
      },
      ...cmsRoutes,
      ...crmRoutes,
      ...marketingRoutes,
      ...blogRoutes,
      ...aseetRoutes,
    ],
  },

  {
    path: "/auth",
    name: "auth1",
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes("auth1"),
  },
];
const defaultTitle = "Business travel";

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

// const router = new VueRouter({
//   routes
// });

router.beforeEach((to, from, next) => {
  // Check if the previous route had a custom title and revert it when navigating away
  if (to.path.includes("/lead/") && from.meta.customTitle) {
    document.title = from.meta.title; // Revert to the original title
  }
  if (to.path.includes("/sales_manage/") && from.meta.customTitle) {
    document.title = from.meta.title; // Revert to the original title
  }
  // Check if the current route's path contains '/lead/'
  if (to.path.includes("/lead/") || to.path.includes("/sales_manage/")) {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  } else {
    document.title = process.env.VUE_APP_TITLE;
  }

  next();
});

export default router;
